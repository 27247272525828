import React from 'react'
import { Link, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

//fontawsome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedinIn,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

//components
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import StayLoop from "../../components/Home/stayloop";
import MoreArticles from "../../components/Home/morearticles";

const BlogSingle = (props) => {
  const { blog: post } = props.data
  const SEOdata = props.data.awardpagedata.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpagedata.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpagedata.edges[0].node.frontmatter?.Hreflang
  const { edges: morepost } = props.data.moreArticles
  return (
    <Layout>
      <Seo
        title={SEOdata ? SEOdata.seo_title : "Top UX Principles For a Successful Business Website"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
      />


      {/* blog description section */}

      <section className="mt-60 lg:mt-20 pt-6-6 pb-35 lg:pt-51 lg:pb-59">
        <div className="container mx-auto">
          <div className="flex flex-wrap justify-between">
            <div className="hidden xl:block w-full lg:w-1/6"></div>
            <div className="w-full lg:w-4/5 xl:w-2/3">
              <h2 className="mb-3-3 lg:mb-1-3 text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-term-primary">{post.frontmatter.title}</h2>
              <div className="blog-inner--cnt text-p4 lg:text-p2 font-worksans-normal text-term-primary"><p>When you design a website, you plan to engage with your customer. How will customers find your website? How will they navigate to main points and how will you define them? Answering these questions helps you create a good space for your customer.</p>
                <p>Planning digital interactions is important to deliver the user experience your customer desires. Customer experience is the interaction between your customer and your brand, user experience is the interaction your customer has with touch points that are defined by the brand.</p>
                <p>There are certain principles that act as a guide through the design of your website’s user experience. These UX design principles will fine tune the experience to meet your user’s needs.</p>
                <h2>Put Yourself in the User’s Shoes</h2>
                <p>It’s not good to create a user experience without the user; in fact, doing so could hurt your engagement. It is smart however, to involve the user early in your development so you can incorporate their needs into the overall UX design.</p>
                <p>Your thought and intuition can help you design the experience but also consider the user’s expectations as you gather data and build insights. Talk to users about their likes and dislikes with websites in terms of features and functionality. What is their favorite navigation?</p>
                <p>As you research, show them some relevant websites and ask questions about different features and why they like them. This focus group on design and interaction will help you achieve success with your users, and get your imagination flowing.</p>
                <p>Remember that you aren’t just designing a website–you’re designing a user-centric website.</p>
                <h2>Define Your Main Purpose</h2>
                <p>Why do you need a website? What do you hope to achieve from having a digital presence?</p>
                <p>Ask yourself these two questions and then identify the key performance indicator for your business. The end result is important to measure the success of the website. However, if you don’t know what you want to achieve or the single parameter determining the success of your website, you’re likely moving in the wrong path.</p>
                <p>Instead of starting the planning or initiating the website development before you ask this question, make sure you kickoff with a strong definition of the purpose of the website.</p>
                <h2>Consistent Design Consistent</h2>
                <p>Consistency is an important part of developing a website. You should achieve consistency in all elements of your design to build trust and aesthetic strength. Imagine using one font on the home page and another on the inner pages. While it may look innovative to you, the user will now feel the same way and might just walk (or click) away.</p>
                <p>Typography is the number one element that demands consistency. Define the typography for your website, and make it consistent. For example, if you use a particular font to define bold and capitals, use the same font throughout the website. Do not use too many fonts. You can change the size and color as needed but try to use no more than two variations throughout the site.</p>
                <p>Another level of consistency comes in the form of colors. You may want to use the brand colors when designing the website. You might have a color palette in mind. Choose only a few closely related colors to reflect your brand. A website that is overly colorful will confuse the user.</p>
                <h2>Keep Your Design Simple</h2>
                <p>Action buttons that are difficult can really confuse a user. You need to keep the design as simple as you possibly can. Based on the user information you gained earlier in the process, you can choose the buttons that are best for your audience and easy to implement. It should be something they know how to use and not strictly something experimental.</p>
                <p>If you are using new clickable buttons and interactive features be sure to include easy navigation to help the user, and make the website user-friendly.</p>
                <p>Never make the user figure out how to use the website. If you cannot walk through it without guidance, make it simpler.</p>
                <h2>Usable Design</h2>
                <p>Usability should always be your ultimate goal. The first step is to choose a simple URL, website buttons and navigation features that users know how to click with a mouse. The idea is to keep the website’s usability intact. That does not mean you shouldn’t be creative in your design. However, if your creativity undermines the usability of the website, you might want to think twice.</p>
                <h2>Focused Designs</h2>
                <p>Where do you want your user to focus when they first visit your site? The answer to this question can help you determine the visual hierarchy for your design. A great way to introduce visual hierarchy is to increase the font size of text you want users to read. Colors can also help distinguish visual hierarchy.</p>
                <p>White space plays an important role too. Make sure your design focuses visitors’ eyes on what they should review first.</p>
                <h2>Conclusion</h2>
                <p>UX is an important aspect of customer engagement, which leads to customer satisfaction. If your interactions are not thorough, you might have a hard time converting.</p>
                <p>DecodeUp gives importance to UX design when developing high quality websites and mobile apps. Our team of UX designers understand the needs of your target and how to deliver them. If you would like to work with us on your website development services, then connect with us by email or phone.</p></div>
            </div>
            <div className="w-full lg:w-1/6">
              <div className="w-full lg:w-3/4 text-left lg:text-right flex flex-wrap lg:flex-col justify-between">
                <div className="lg:mb-39">
                  <h5 className="lg:mb-1-1 uppercase text-cyan font-poppings-bold text-rh6 md:text-h6">
                    {post.frontmatter.blog_category}
                  </h5>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3">
                    {post.frontmatter.date}
                  </p>
                </div>
                <div className="flex items-center lg:items-end lg:flex-col ">
                  <Link to={post.frontmatter.twitter} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary "
                      icon={faTwitter}
                    />
                  </Link>
                  <Link to={post.frontmatter.linkedin} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="mx-8 lg:mx-0 lg:my-5-1 font-awesome text-2xl text-term-primary "
                      icon={faLinkedinIn}
                    />
                  </Link>
                  <Link to={post.frontmatter.facebook} target="_blank" className="text-right opacity-20 hover:opacity-100 transform transition duration-500 ease">
                    <FontAwesomeIcon
                      className="font-awesome text-2xl text-term-primary"
                      icon={faFacebookF}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* more articles section */}
      {morepost.length &&
        <section className="pb-50 lg:pb-1-04">
          <MoreArticles articles={morepost} />
        </section>
      }

      {/* stay in loop section */}
      <StayLoop />

    </Layout>

  )
}

export default BlogSingle

export const BlogPageQuery = graphql`
  query BlogPage10($id: String!) {
    blog: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        blog_category
        templateKey
        facebook
        linkedin
        twitter
        date(formatString: "DD MMM, YYYY")
        
      }
    }
    moreArticles: allMarkdownRemark(
      filter: { 
              id: { ne: $id },
              frontmatter: { templateKey: { eq: "single-blog" } }
            }
      ) {
       edges {
         node {
           id
           fields {
             slug
           }
           frontmatter {
             title
             blog_category
             templateKey
             featured_image {              
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
             date(formatString: "DD MMM, YYYY")
           }
         }
       }
     }
     awardpagedata: allMarkdownRemark(filter: {id: {eq: $id}}) {
      edges {
        node {
          frontmatter {
            Hreflang {
              hreflang_1
              hreflang_3
              hreflang_2
              hreflang_4
            }
            Schema {
              aggregate_rating_schema
              breadcrumb_schema
              local_business_schema
              logo_schema
              organization_schema
              site_navigation_schema
              sitelinks_schema
              speakable_specification_schema
            }
            seo_metadata {
              facebook_url
              feature_image_height
              image_format
              img_width
              page_last_modified_time
              page_publish_date
              seo_description
              seo_keyword
              seo_title
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              twitter_image_alt_tag
              twitter_page_username
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
          }
        }
      }
    }
  }`